import type { RouteRecordRaw } from 'vue-router'
import renderVersionsRoute from './renderVersions'
import validationsRoute from './validations'
import interiorOptionsRoute from './interiorOptions'
import timeOfDayOptionsRoute from './timeOfDayOptions'

const renderSettingsRoute: RouteRecordRaw = {
  path: '/renderSettings',
  name: 'renderSettings',
  redirect: { name: 'interiorOptions' },
  meta: {
    breadcrumb: 'renderSettings',
    menu: {
      key: 'renderSettings',
      icon: 'mdi-file-cog-outline',
      name: 'renderSettings'
    }
  },
  children: [interiorOptionsRoute, timeOfDayOptionsRoute, renderVersionsRoute, validationsRoute]
}

export default renderSettingsRoute
