<template>
  <vis-loader
    :loading="unit.isFetching || buildings.isFetching || statuses.isFetching || fields.isFetching"
  >
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <unit-form
        :statuses="statuses.data!"
        :fields="fields.data!"
        :buildings="buildings.data!"
        @submit="onSubmit"
      />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useUnitStore from '@/stores/unit'
import UnitForm from '@/components/forms/UnitForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useFieldStore from '@/stores/field'
import useStatusStore from '@/stores/status'
import type Unit from '@/types/unit'
import useBuildingStore from '@/stores/building'

const { unit, listUnits, createUnit } = useUnitStore()

const { fields, listFields } = useFieldStore()
fields.data || listFields()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const onSubmit = useSubmitThenRoute(
  (unit: Partial<Unit>) => createUnit(unit),
  (b) => ({ name: 'updateUnit', params: { unitId: b.data!.id } }),
  () => listUnits()
)
</script>
