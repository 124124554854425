<template>
  <vis-loader :loading="images.isFetching || patch.isFetching">
    <vis-form @submit.prevent="onSubmit">
      <v-tabs v-model="tab" bg-color="primary">
        <v-tab
          v-for="projectImagesCategory of ProjectImagesCategory"
          :key="projectImagesCategory"
          :value="projectImagesCategory"
        >
          {{ projectImagesCategory }}
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item
          v-for="projectImagesCategory of ProjectImagesCategory"
          :key="projectImagesCategory"
          :value="projectImagesCategory"
        >
          <vis-sheet>
            <images-table
              :category="projectImagesCategory"
              :project="project.data!"
              :images="images.data!.filter((i) => i.category === projectImagesCategory)"
              @reorder="onReorder"
              @delete="onDelete"
            />
            <vis-file-input
              v-model="files"
              multiple
              accept="image/png, image/jpeg, image/bmp"
              label="images"
            />
          </vis-sheet>
        </v-window-item>
      </v-window>
      <v-row>
        <v-col cols="12" class="text-right">
          <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
        </v-col>
      </v-row>
    </vis-form>
  </vis-loader>
</template>

<script setup lang="ts">
import ImagesTable from '@/components/tables/ImagesTable.vue'
import useImageStore from '@/stores/image'
import useProjectStore from '@/stores/project'
import { ProjectImagesCategory } from '@/types/image'
import { ref } from 'vue'
import type Image from '@/types/image'
import { useI18n } from 'vue-i18n'

const { project } = useProjectStore()
const { t } = useI18n()

const { images, patch, listImages, createImage, patchImages, deleteImage } = useImageStore()
images.data || listImages()

const tab = ref<ProjectImagesCategory>(Object.values(ProjectImagesCategory)[0])
const files = ref<Array<File>>([])

const onDelete = async (image: Image) => {
  if (confirm(t('areYouSureYouWantToDeleteTheImage', [image.id]))) {
    await deleteImage(image.id)
    listImages()
  }
}

const onReorder = async (reordered: Array<Partial<Image>>) => {
  await patchImages(reordered)
  listImages()
}

const onSubmit = async () => {
  const formData = new FormData()
  for (const file of files.value) {
    formData.append(tab.value, file)
  }
  await createImage(formData)
  listImages()
}
</script>
