<template>
  <v-main>
    <v-container fluid class="h-100 overflow-auto">
      <h4>
        {{ $t('selectAProject') }}
        <if-scoped :scopes="KeycloakScope.PROJECT_CREATE">
          <vis-add-icon-btn :to="{ name: 'addProject' }" class="float-right" />
        </if-scoped>
        <if-scoped :scopes="KeycloakScope.CLIENT_LIST">
          <v-tooltip location="left">
            <template #activator="{ props }">
              <vis-icon-btn
                v-bind="props"
                icon="mdi-domain"
                :to="{ name: 'clients' }"
                class="float-right mr-2"
              />
            </template>
            {{ $t('manageClients') }}
          </v-tooltip>
        </if-scoped>
      </h4>
      <vis-loader :loading="projects.isFetching || clients.isFetching">
        <v-row>
          <v-col sm="3" cols="12">
            <data-table-filter
              :columns="columns"
              :items="items"
              @update="filtered = $event"
              search-on-bottom
            />
          </v-col>
          <v-col sm="9" cols="12">
            <data-table :columns="columns" :items="filtered" @select="selectProject" />
          </v-col>
        </v-row>
      </vis-loader>
    </v-container>
  </v-main>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import useClientStore from '@/stores/client'
import useContextStore from '@/stores/context'
import useProjectStore from '@/stores/project'
import type Project from '@/types/project'
import useProjectTableDefinitions from '@/composables/tableDefinitions/useProjectTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import IfScoped from '@/components/control/IfScoped.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const router = useRouter()
const { projects, listProjects } = useProjectStore()
const { selectProjectId } = useContextStore()
projects.data || listProjects()

const { clients, listClients } = useClientStore()
clients.data || listClients()

const items = useWatchFetchResult(projects, [])
const columns = computed(() => useProjectTableDefinitions(clients.data!))
const filtered = ref<Array<Project>>([])

function selectProject(project: Project) {
  selectProjectId(project.id!)
  router.push({ name: 'dashboard' })
}
</script>
