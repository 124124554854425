import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export function sentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: 'https://ad1ba6f86f314754f79c7bf3bf141f4b@o1139643.ingest.us.sentry.io/4508137952313344',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost:7020',
      'https://admin-beta.visuado.com',
      'https://admin-beta.qistage.com',
      'https://admin.visuado.com',
      'https://admin.qistage.com'
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
