<template>
  <v-app>
    <v-app-bar color="primary">
      <template v-slot:prepend>
        <v-app-bar-nav-icon
          icon="mdi-menu"
          :disabled="!drawable"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title class="text-center"
        ><img style="margin-top: 9px; height: 30px" :src="logo"
      /></v-app-bar-title>
      <template v-slot:append>
        <div class="pl-4 pr-2">
          <vis-language-switcher class="mr-4" />
          <vis-btn icon class="avatar" size="small" color="secondary">
            {{ initials }}
            <v-menu activator="parent" location="bottom end" transition="fade-transition">
              <v-list density="compact" min-width="250" slim>
                <v-list-item link :href="accountUrl">{{ $t('editAccount') }}</v-list-item>
                <v-list-item link @click="keycloakProvider.logout()">{{
                  $t('logout')
                }}</v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item min-height="24">
                  <template v-slot:subtitle>
                    <div class="text-caption">
                      {{ $t('loggedInAs', [`${firstName} ${lastName} (${email})`]) }}
                    </div>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </vis-btn>
        </div>
      </template>
    </v-app-bar>
    <router-view />
    <v-snackbar
      v-for="(message, index) of appStore.messages"
      :key="index"
      close-on-content-click
      timer
      location="bottom right"
      variant="flat"
      :color="message.color"
      :style="{ 'margin-bottom': `${60 * index + 8}px` }"
      :model-value="true"
      @update:model-value="closeSnackbar($event, message)"
    >
      {{ message.message }}
      <template v-if="message.action" #actions>
        <v-btn :color="`text-${message.color}`" variant="text" :to="message.action">
          {{ $t('go') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import { ref, provide } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import logo from '@/assets/logo.webp'
import { Drawer, Drawable } from '@/injectionKeys'
import useAuthStore from '@/stores/auth'
import { computed } from 'vue'
import useKeycloakProvider from '@/composables/useKeycloakProvider'
import VisLanguageSwitcher from '@/components/inputs/VisLanguageSwitcher.vue'
import useAppStore, { type Message } from '@/stores/app'

const drawer = ref(true)
provide(Drawer, drawer)

const drawable = ref(false)
provide(Drawable, drawable)
onBeforeRouteUpdate(() => {
  drawable.value = false
})

const authStore = useAuthStore()
const { firstName, lastName } = authStore

const keycloakProvider = useKeycloakProvider()

const email = keycloakProvider.keycloak.idTokenParsed!.email
const accountUrl = keycloakProvider.keycloak.idTokenParsed!.iss + '/account'

const initials = computed(() => `${firstName?.charAt(0)}${lastName?.charAt(0)}`)

const appStore = useAppStore()
const closeSnackbar = (open: boolean, message: Message) => {
  if (!open) {
    appStore.removeMessage(message)
  }
}
</script>

<style scoped>
.avatar {
  border: 2px solid rgb(var(--v-theme-on-primary));
}
</style>
