<template>
  <v-main>
    <v-container fluid class="h-100 overflow-auto">
      <template v-if="route.name === 'clients'">
        <h4>
          {{ $t('clients') }}
          <if-scoped :scopes="KeycloakScope.CLIENT_CREATE">
            <vis-icon-btn
              icon="mdi-arrow-left"
              :to="{ name: 'selectProject' }"
              class="float-right"
            />
          </if-scoped>
        </h4>
        <vis-loader :loading="clients.isFetching">
          <v-row>
            <v-col sm="3" cols="12">
              <data-table-filter
                :columns="columns"
                :items="items"
                @update="filtered = $event"
                search-on-bottom
              />
            </v-col>
            <v-col sm="9" cols="12">
              <data-table
                :columns="columns"
                :items="filtered"
                :project="project.data!"
                v-on="{
                  add: check(KeycloakScope.CLIENT_CREATE) ? onAdd : undefined,
                  select: check(KeycloakScope.CLIENT_UPDATE) ? onSelect : undefined,
                  delete: check(KeycloakScope.CLIENT_DELETE) ? onDelete : undefined
                }"
              />
            </v-col>
          </v-row>
        </vis-loader>
      </template>
      <template v-else>
        <router-view />
      </template>
    </v-container>
  </v-main>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useClientStore from '@/stores/client'
import useProjectStore from '@/stores/project'
import useClientTableDefinitions from '@/composables/tableDefinitions/useClientTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import IfScoped from '@/components/control/IfScoped.vue'
import type Client from '@/types/client'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { clients, listClients, deleteClient } = useClientStore()
clients.data || listClients()

const items = useWatchFetchResult(clients, [])
const columns = computed(() => useClientTableDefinitions())
const filtered = ref<Array<Client>>([])

const onAdd = () => router.push({ name: 'clientCreate' })

const onSelect = (client: Client) =>
  router.push({
    name: 'clientUpdate',
    params: {
      clientId: client.id
    }
  })

const onDelete = async (client: Client) => {
  if (confirm(t('areYouSureYouWantToDeleteTheClient', [client.id]))) {
    await deleteClient(client.id)
    listClients()
  }
}
</script>
