<template>
  <vis-data-table :class="category" :headers="columns" :items="images">
    <template #top />
    <template #bottom />
    <template #body="{ items }">
      <template v-if="images.length > 0">
        <draggable
          :list="[...items].sort((a, b) => a.order - b.order)"
          item-key="id"
          tag="tbody"
          @change="onReorder"
        >
          <template #item="{ element: image }: { element: Image }">
            <tr class="v-data-table__tr v-data-table__tr--clickable">
              <td class="v-data-table__td v-data-table-column--align-start">
                <img :src="`${project.assetRoot}/images/${image.category}/${image.filename}`" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                {{ image.filename }}
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip v-if="hasDeleteEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(image)" />
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasReorderEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-drag-icon-btn v-bind="props" />
                  </template>
                  {{ $t('dragToReorder') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div></template
      >
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable'
import { computed, getCurrentInstance, onMounted } from 'vue'
import type Image from '@/types/image'
import type Project from '@/types/project'
import type { ProjectImagesCategory } from '@/types/image'
import { useI18n } from 'vue-i18n'

const { project, images, category } = defineProps<{
  project: Project
  category: ProjectImagesCategory
  images: Array<Image>
}>()

const emit = defineEmits<{
  delete: [image: Image]
  reorder: [reordered: Array<Partial<Image>>]
}>()

defineSlots<{
  top(props: {}): any
}>()

const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)
const hasReorderEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onReorder)

const { t } = useI18n()

const columns = [
  { title: t('image'), key: 'image', sortable: false },
  { title: t('filename'), key: 'name', sortable: false },
  { title: '', key: 'actions', sortable: false }
]

function onRowDelete(image: Image) {
  emit('delete', image)
}

const onReorder = (event: Moved<Image>) => {
  const copy = [...images].sort((a, b) => a.order - b.order)
  const left = copy[event.moved.oldIndex]
  const right = copy[event.moved.newIndex]
  copy[event.moved.oldIndex] = right
  copy[event.moved.newIndex] = left
  copy.forEach((c, i) => {
    c.order = i
  })
  emit('reorder', copy)
}

onMounted(() => {
  if (images.length > 0) {
    const sortableTbody = document.querySelector(
      `.${category} > .v-table__wrapper > table > tbody > tbody`
    )
    const tbody = document.querySelector(`.${category} > .v-table__wrapper > table > tbody`)
    tbody!.parentNode!.append(sortableTbody!)
    tbody!.remove()
  }
})
</script>

<style scoped>
:deep(img) {
  display: block;
  height: 100%;
  margin: 0 auto;
}
</style>
