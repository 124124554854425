<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <vis-text-field
          name="callbackChallenge"
          :placeholder="$t('callbackChallenge')"
          :label="$t('callbackChallenge')"
          disabled
          readonly
          v-model="integration.callbackChallenge"
        />
      </v-col>
      <v-col cols="12">
        <vis-text-field
          name="callbackJsonPathIdentifier"
          :placeholder="$t('callbackJsonPathIdentifier')"
          :label="$t('callbackJsonPathIdentifier')"
          v-model="integration.callbackJsonPathIdentifier"
        />
      </v-col>
      <v-col cols="12">
        <vis-text-field
          name="callbackRequestUrl"
          :placeholder="$t('callbackRequestUrl')"
          :label="$t('callbackRequestUrl')"
          v-model="integration.callbackRequestUrl"
        />
      </v-col>
      <v-col cols="12">
        <vis-text-field
          name="oAuth2TokenEndpoint"
          :placeholder="$t('oAuth2TokenEndpoint')"
          :label="$t('oAuth2TokenEndpoint')"
          v-model="integration.oAuth2TokenEndpoint"
        />
      </v-col>
      <v-col cols="12">
        <v-btn class="w-100" @click="configureOAuthData = !configureOAuthData">{{
          $t('toggleAuthConfig')
        }}</v-btn>
      </v-col>
      <template v-if="configureOAuthData">
        <v-col cols="12">
          <vis-text-field
            name="oAuth2ClientId"
            :placeholder="$t('oAuth2ClientId')"
            :label="$t('oAuth2ClientId')"
            v-model="integration.oAuth2ClientId"
          />
        </v-col>
        <v-col cols="12">
          <vis-text-field
            name="oAuth2ClientSecret"
            :placeholder="$t('oAuth2ClientSecret')"
            :label="$t('oAuth2ClientSecret')"
            v-model="integration.oAuth2ClientSecret"
          />
        </v-col>
        <v-col cols="12">
          <vis-text-field
            name="oAuth2ClientScopes"
            :placeholder="$t('oAuth2ClientScopes')"
            :label="$t('oAuth2ClientScopes')"
            v-model="integration.oAuth2ClientScopes"
          />
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type Integration from '@/types/integration'

const props = defineProps<{ integration?: Integration }>()
const emit = defineEmits<{
  submit: [integration: Partial<Integration>]
}>()
const configureOAuthData = ref(false)

const integration = reactive<Partial<Integration>>(props.integration || {})

const { formRef, submit } = useFormPartials(integration, (partial: Partial<Integration>) =>
  emit('submit', partial)
)
</script>
