import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import '@vuepic/vue-datepicker/dist/main.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import VueDatePicker from '@vuepic/vue-datepicker'
import i18n from './i18n'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'

const vuetify = createVuetify({
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n })
  },
  components: { ...components, VueDatePicker: VueDatePicker },
  directives,
  aliases: {
    VisForm: components.VForm,
    VisSubmitBtn: components.VBtn,
    VisBackBtn: components.VBtn,
    VisCancelBtn: components.VBtn,
    VisIconBtn: components.VBtn,
    VisViewIconBtn: components.VBtn,
    VisAddIconBtn: components.VBtn,
    VisRemoveIconBtn: components.VBtn,
    VisEditIconBtn: components.VBtn,
    VisDragIconBtn: components.VBtn,
    VisBackIconBtn: components.VBtn,
    VisBtn: components.VBtn,
    VisTextField: components.VTextField,
    VisFileInput: components.VFileInput,
    VisTextarea: components.VTextarea,
    VisSelect: components.VSelect,
    VisCheckbox: components.VCheckbox,
    VisSwitch: components.VSwitch,
    VisRadio: components.VRadio,
    VisRangeSlider: components.VRangeSlider,
    VisSlider: components.VSlider,
    VisLoader: components.VSkeletonLoader,
    VisDataTable: components.VDataTable,
    VisSheet: components.VSheet
  },
  defaults: {
    VisForm: {
      style: 'width: 100%'
    },
    VisBtn: {
      size: 'large',
      color: 'primary'
    },
    VisSubmitBtn: {
      size: 'large',
      color: 'success',
      prependIcon: 'mdi-check-circle'
    },
    VisBackBtn: {
      size: 'large',
      color: 'success',
      prependIcon: 'mdi-arrow-left'
    },
    VisCancelBtn: {
      size: 'large',
      color: 'error',
      prependIcon: 'mdi-cancel'
    },
    VisIconBtn: {
      size: 'small',
      density: 'comfortable',
      color: 'primary'
    },
    VisViewIconBtn: {
      icon: 'mdi-eye',
      size: 'small',
      density: 'comfortable',
      color: 'primary'
    },
    VisAddIconBtn: {
      icon: 'mdi-plus',
      size: 'small',
      density: 'comfortable',
      color: 'success'
    },
    VisRemoveIconBtn: {
      icon: 'mdi-delete',
      size: 'small',
      density: 'comfortable',
      color: 'error'
    },
    VisEditIconBtn: {
      icon: 'mdi-pencil',
      size: 'small',
      density: 'comfortable',
      color: 'primary'
    },
    VisDragIconBtn: {
      icon: 'mdi-drag-vertical',
      size: 'small',
      density: 'comfortable',
      color: 'white'
    },
    VisBackIconBtn: {
      icon: 'mdi-arrow-left',
      size: 'small',
      density: 'comfortable',
      color: 'grey'
    },
    VisLoader: {
      height: '100%',
      width: '100%',
      type: 'list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line'
    },
    VisDataTable: {
      height: '100%',
      hover: true,
      itemsPerPage: -1,
      density: 'compact',
      fixedHeader: true
    },
    VisTextField: {
      variant: 'solo',
      hideDetails: true
    },
    VisFileInput: {
      variant: 'solo',
      hideDetails: true
    },
    VisTextarea: {
      variant: 'solo',
      hideDetails: true
    },
    VisSelect: {
      variant: 'solo',
      hideDetails: true
    },
    VisSwitch: {
      inset: true,
      color: 'success',
      hideDetails: true,
      density: 'compact'
    },
    VisRangeSlider: {
      color: 'primary',
      hideDetails: true,
      density: 'compact'
    },
    VisSlider: {
      color: 'primary',
      hideDetails: true,
      density: 'compact'
    },
    VisSheet: {
      class: 'pa-4 mb-4 w-100'
    }
  },
  theme: {
    themes: {
      light: {
        colors: {
          background: '#eaeaee',
          surface: '#FFFFFF',
          primary: '#070c46',
          secondary: '#020527',
          accent: '#01010a',
          error: '#FF5349',
          info: '#555461',
          success: '#1CC07C',
          warning: '#FF9057',
          'on-background': '#757575',
          'on-surface': '#757575',
          'on-primary': '#707ceb',
          'on-secondary': '#707ceb',
          'on-accent': '#707ceb',
          'on-error': '#FFFFFF',
          'on-info': '#FFFFFF',
          'on-success': '#FFFFFF',
          'on-warning': '#FFFFFF'
        }
      }
    }
  }
})

export default vuetify
